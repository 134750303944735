import Cookies from 'js-cookie';

export const popupLeadFormCookieName = 'popupLeadForm';
export const popupLeadFormSubmittedBeforeCookieName = 'popupLeadFormSubmittedBefore';
export const popupLeadFormExcludedPages = ['saveInquiry']

export const setCookie = (name, value, expires = 365) => {
    Cookies.set(name, value, {expires: expires, path: '/'});
};

export const getCookie = (name) => {
    return Cookies.get(name);
};

export const deleteCookie = (name) => {
    Cookies.remove(name);
};
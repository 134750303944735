import {isRTL} from "../utilities/isRTL";

export const ourProjectsSliderSettings = (sliderClass) => {
    return {
        // slidesToScroll: isRTL() === true ? 1 : -1,
        slidesToScroll: 4,
        slidesToShow: 4,
        prevArrow: $(`.${sliderClass} .slider-arrows .prev`),
        nextArrow: $(`.${sliderClass} .slider-arrows .next`),
        autoplaySpeed: 3000,
        speed: 1000,
        rtl: false,
        infinite: false,
        cssEase: 'cubic-bezier( 0.420,  0.000,  0.580,  1.000 )',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            }, {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 786,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            }, {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1.25,
                    slidesToScroll: 1,
                },
            },
        ],
    }
}
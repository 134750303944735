import "./utilities/jqueryLoad.js";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import 'slick-carousel';
import {runApp} from "./run-app";

$(window).on("load", function () {
    document.body.className = document.body.className.replace("no-js", "js");
    $('#preloader').addClass('d-none')
    $('body').removeClass('overflow-hidden');
    runApp();

});


import { gsap } from "gsap";
import { isRTL } from "../utilities/isRTL";
import {
  addPlayer,
  getPlayer,
  removePlayer,
} from "../utilities/youtube-video.js";

let player;
window.onload = () => {
  let tag = document.createElement("script");
  tag.src = "https://www.youtube.com/iframe_api";
  let firstScriptTag = document.getElementsByTagName("script")[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
};
const onPlayerReady = (event) => {
  event.target.playVideo();
};

export const videoSliderExpandAnimation = {
  sliderSettings: () => {
    return {
      slidesToShow: 3,
      slidesToScroll: 1,
      autoPlay: true,
      speed: 1000,
      autoplaySpeed: 3000,
      prevArrow: $(".videos-slider-wrapper .slider-arrows .prev"),
      nextArrow: $(".videos-slider-wrapper .slider-arrows .next"),
      infinite: true,
      rtl: isRTL(),
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: "60px",
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: "60px",
          },
        },
      ],
    };
  },
  openVideo: (el, sectionName) => {
    const slide = el.currentTarget;
    let clone = slide.cloneNode(true);
    let rect = slide.getBoundingClientRect();
    $(el.currentTarget)
      .closest("section")
      .addClass("expand-animation-is-active");

    clone.style.width = rect.width + "px";
    clone.style.height = rect.height + "px";
    clone.style.left = rect.left + "px";
    clone.setAttribute("data-left", rect.left);
    clone.setAttribute("data-height", rect.height);
    clone.setAttribute("data-width", rect.width);

    let section = document.querySelectorAll(`.${sectionName}`);

    section[0].append(clone);

    const video = clone.querySelectorAll(".video")[0];
    let videoId = video.getAttribute("data-video-id");
    let player = new YT.Player(video, {
      videoId: videoId,
      events: {
        onReady: onPlayerReady,
      },
    });

    addPlayer(videoId, player);

    let sliderWrapper = document.querySelectorAll(".videos-slider-wrapper");
    gsap.set(sliderWrapper, { opacity: 0, display: "none" });
    gsap.to(clone, {
      width: "100%",
      height: "90.6rem",
      left: 0,
      duration: 1,
      onComplete: async () => {
        const img = clone.querySelectorAll("img");
        const iframe = clone.querySelectorAll("iframe");
        const closeButton = clone.querySelectorAll(".close-video");
        gsap.set(img, { display: "none" });
        gsap.set(iframe, { display: "block" });
        gsap.set(closeButton, { display: "block" });
      },
    });
  },
  closeVideo: (el) => {
    $(el.currentTarget)
      .closest("section")
      .removeClass("expand-animation-is-active");

    const closeButton = el;
    const slide = closeButton.closest(".slide");
    const width = slide.getAttribute("data-width");
    const height = slide.getAttribute("data-height");
    const left = slide.getAttribute("data-left");
    const img = slide.querySelectorAll("img");
    const iframe = slide.querySelectorAll("iframe");
    const videoId = $(iframe).attr("data-video-id");
    getPlayer(videoId).stopVideo();
    removePlayer(videoId);
    gsap.set(img, { display: "block" });
    gsap.set([iframe, closeButton], { display: "none" });

    gsap.to(slide, {
      width: width,
      height: height,
      left: left,
      duration: 1,
      onComplete: async () => {
        let sliderWrapper = document.querySelectorAll(".videos-slider-wrapper");
        slide.remove();
        gsap.set(sliderWrapper, { opacity: 1, display: "block" });
        $(sliderWrapper).find(".slider").slick("setPosition");
      },
    });
  },
};

export let youtubePlayers = [];
export const getPlayer = (id) => {
    return youtubePlayers.filter((p) => p.id === id)[0]?.player
}
export const addPlayer = (id, player) => {
    youtubePlayers.push({
        id: id,
        player: player
    })
}

export const removePlayer = (id) => {
    youtubePlayers = youtubePlayers.filter((item) => item.id !== id);
}
export const welcomeSliderSettings = (sliderClass) => {
    return {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 1000,
        prevArrow: $(`.${sliderClass} .slider-arrows .prev`),
        nextArrow: $(`.${sliderClass} .slider-arrows .next`),
        infinite: true,
        fade: true,
    }
}

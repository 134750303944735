import counterUp from "counterup2";

export const scrollTrigger = (selector, options = {}) => {
    let els = document.querySelectorAll(selector);
    els = Array.from(els);
    els.forEach((el) => {
        addObserver(el, options);
    });
}


function addObserver(el, options) {
    // Check if IntersectionObserver is supported
    if (!("IntersectionObserver" in window)) {
        if (options.cb) {
            console.log(1)
            options.cb(el);
        } else {
            console.log(2)
            entry.target.classList.add('in-viewport-active');
        }
        return;
    }
    let observer = new IntersectionObserver((entries, observer) => {
        // This takes a callback function which receives two arguments: the elements list and the observer instance
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                if (options.cb) {
                    options.cb(el);
                } else {
                    entry.target.classList.add('in-viewport-active');
                }
                //console.log(entry.target.classList)
                entry.target.inViewPortCount = (entry.target.inViewPortCount || 0) + 1;
                if (entry.target.classList.contains('with-counter') && entry.target.inViewPortCount < 2) {
                    //if (window.innerWidth > 768) {
                    const elements = entry.target.querySelectorAll('.counter');
                    elements.forEach((item) => {
                        counterUp(item, {
                            duration: 1000,
                            delay: 16,
                        })
                    })
                    //}
                }
                if (entry.target.classList.contains('play-in-viewport')) {
                    entry.target.play();
                }
                observer.unobserve(entry.target);
            } else {
                entry.target.classList.remove('in-viewport-active');

                //entry.target.classList.remove("is-visible");
            }

        });
    }, options);
    observer.observe(el);
}


function handleIntersection(entries, observer) {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            entry.target.classList.add('visible');
            $('.register-inquiry-btn').addClass('d-none');
        } else {
            entry.target.classList.remove('visible');
            $('.register-inquiry-btn').removeClass('d-none');
        }
    });
}

// Create an Intersection Observer
const observer = new IntersectionObserver(handleIntersection, {threshold: 0.15});

// Target the section you want to observe
const targetSection = document.getElementsByClassName('register-your-inquiry-section');
if (targetSection[0]) {
// Start observing the target section
    observer.observe(targetSection[0]);
}

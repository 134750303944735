import {gsap} from 'gsap';
import {getPlayer} from "../utilities/youtube-video.js";

const getElements = (itemExpand) => {
    const expandAnimation = itemExpand.closest('.expand-animation');
    const hides = expandAnimation.querySelectorAll('.item-hide-content');
    const list = expandAnimation.querySelector('.expand-animation-list');
    const content = expandAnimation.querySelector('.expand-animation-content');
    const details = itemExpand.querySelector('.item-expand-details');
    const otherItems = expandAnimation.querySelectorAll('.item-expand:not(.active)');
    const closeButton = expandAnimation.querySelector('.back-expand-animation');
    return {expandAnimation, hides, list, content, details, otherItems, closeButton}
}

let detailsAnimation;
const animationSpeed = 1.5;
export const activateItemExpand = (el) => {
    if (!el.currentTarget.closest('.expand-animation').classList.contains('active')) {
        if ($(el.currentTarget).hasClass('construction')) {
            console.log($(el.target))
            if (!$(el.target).is('div.poster') && !$(el.target).is('h3.title')) {
                return;
            }
        }
        $(el.currentTarget).closest('section').addClass('expand-animation-is-active');
        const itemExpand = el.currentTarget;
        itemExpand.classList.add('active');
        const {expandAnimation, closeButton, hides, list, content, details, otherItems} = getElements(itemExpand);
        expandAnimation.classList.add('active');
        expandAnimation.classList.add('in-progress');
        $(itemExpand).find('.poster').addClass('no-play-icon');
        const mcmMobileAppVideoId = $(itemExpand).find('.mcm-mobile-app-video').attr('data-video-id');
        if (mcmMobileAppVideoId) {
            getPlayer(mcmMobileAppVideoId).playVideo();
        }
        gsap.set(closeButton, {
            delay: animationSpeed,
            display: 'block'
        })

        detailsAnimation = gsap.to(details, {
            delay: animationSpeed,
            opacity: 1,
            duration: animationSpeed,
            display: 'block',
            onComplete: () => {
                expandAnimation.classList.remove('in-progress');
                $(itemExpand).find('.slider').slick('setPosition');
                if (itemExpand.classList.contains('is-video')) {
                    const videoId = $(itemExpand).find('.youtube-video').attr('data-video-id');
                    getPlayer(videoId).playVideo();

                }
            }
        })
    }
}

export const deactivateItemExpand = (el) => {
    if (el.currentTarget.closest('.expand-animation').classList.contains('active')) {

        if ($(el.currentTarget).closest('section').find('.expand-animation.active').length <= 1) {

            $(el.currentTarget).closest('section').removeClass('expand-animation-is-active');
        }
        const itemExpand = el.currentTarget.closest('.expand-animation').querySelector('.item-expand.active');
        if (itemExpand.classList.contains('is-video')) {
            const videoId = $(itemExpand).find('.youtube-video').attr('data-video-id');
            console.log(videoId)
            getPlayer(videoId).pauseVideo();
        }
        const mcmMobileAppVideoId = $(itemExpand).find('.mcm-mobile-app-video').attr('data-video-id');
        if (mcmMobileAppVideoId) {
            getPlayer(mcmMobileAppVideoId).pauseVideo();
        }

        itemExpand.classList.remove('active');

        const {expandAnimation, closeButton, hides, list, content, details, otherItems} = getElements(itemExpand);
        expandAnimation.classList.remove('active');
        expandAnimation.classList.add('in-progress');

        gsap.set(details, {
            display: 'none'
        })
        gsap.set(closeButton, {
            display: 'none'
        })
        /*    detailsAnimation.eventCallback("onComplete", () => {
                gsap.set(details, {
                    display: 'none'
                })

                gsap.set(closeButton, {
                    display: 'none'
                })
            });*/
        detailsAnimation.kill();

        setTimeout(() => {
            expandAnimation.classList.remove('in-progress');
            $(itemExpand).find('.poster').removeClass('no-play-icon');
        }, 1000)
    }
}
import {gsap} from 'gsap';

const getElements = (el) => {
    const itemsAnimation = el.currentTarget.closest(`.items-animation`);
    const itemWrapper = el.currentTarget.closest('.item-wrapper');

    return {itemsAnimation, itemWrapper}
}
export const setCurrentItemAnimation = (el) => {
    const {itemsAnimation, itemWrapper} = getElements(el)
    const item = el.currentTarget;
    const itemWrappers = itemsAnimation.querySelectorAll('.item-wrapper');

    if (!item.classList.contains('active')) {
        $(itemsAnimation).addClass('in-progress');
        setTimeout(() => {
            $(itemsAnimation).removeClass('in-progress');
        }, 1000);
        itemWrappers.forEach((iw) => {
            iw.classList.remove('active')
            iw.querySelector('.item').classList.remove('active')
        })
        itemsAnimation.classList.add('active')
        itemWrapper.classList.add('active');
        item.classList.add('active');
        const itemDetails = itemsAnimation.querySelector(`#${itemWrapper.id.replace(/Wrapper/g, 'Details')}`);
        if (itemDetails) {
            itemDetails.classList.add('active');
        }
    }
}
export const closeItemAnimation = (el) => {
    const {itemsAnimation, itemWrapper} = getElements(el)
    $(itemsAnimation).addClass('in-progress');
    setTimeout(() => {
        $(itemsAnimation).removeClass('in-progress');
    }, 1000);
    itemWrapper.classList.remove('active');
    itemWrapper.querySelector('.item').classList.remove('active');
    itemsAnimation.classList.remove('active');
    const itemDetails = itemsAnimation.querySelector(`#${itemWrapper.id.replace(/Wrapper/g, 'Details')}`);
    if (itemDetails) {
        itemDetails.classList.remove('active');
    }
}

const animatedList = () => {
    $('.sustainability .item-wrapper').each((index, item) => {
        gsap.fromTo($(item), {
            y: "100%",
        }, {
            y: '0',
            duration: 1,
            delay: (index + 1) * 0.1
        });
    })
}

animatedList();
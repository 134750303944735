import {youtubePlayers} from "./utilities/youtube-video";

export const initVideos = () => {
    $.getScript("https://www.youtube.com/iframe_api", (f) => {
        window.YT.ready(() => {
            const youtubeVideos = document.querySelectorAll('.youtube-video');
            const youtubeVideosBg = document.querySelectorAll('.youtube-video-bg');
            const youtubeVideosBgMap = document.querySelectorAll('.youtube-video-bg-map');
            const youtubeVideosBgLoop = document.querySelectorAll('.youtube-video-bg-loop');
            youtubeVideos.forEach((item) => {
                const videoId = item.getAttribute('data-video-id');
                const player = new YT.Player(item, {
                    videoId: videoId,
                    events: {
                        'onReady': () => {
                        }
                    },
                });
                youtubePlayers.push({
                    id: videoId,
                    player: player
                })
            })
            youtubeVideosBg.forEach((item) => {
                const videoId = item.getAttribute('data-video-id');
                const player = new YT.Player(item, {
                    videoId: videoId,
                    playerVars: {
                        autoplay: 1, // Auto-play the video on load
                        autohide: 1, // Hide video controls when playing
                        disablekb: 1,
                        controls: 0, // Hide pause/play buttons in player
                        showinfo: 0, // Hide the video title
                        modestbranding: 1, // Hide the Youtube Logo
                        loop: 1, // Run the video in a loop
                        fs: 0, // Hide the full screen button
                        rel: 0,
                        enablejsapi: 1,
                    },
                    events: {
                        onReady: function (e) {
                            e.target.mute();
                            e.target.playVideo();
                        },
                    }
                });
                youtubePlayers.push({
                    id: videoId,
                    player: player
                })
            })
            youtubeVideosBgMap.forEach((item) => {
                const videoId = item.getAttribute('data-video-id');
                const player = new YT.Player(item, {
                    videoId: videoId,
                    playerVars: {
                        autoplay: 1, // Auto-play the video on load
                        autohide: 1, // Hide video controls when playing
                        disablekb: 1,
                        controls: 0, // Hide pause/play buttons in player
                        showinfo: 0, // Hide the video title
                        modestbranding: 1, // Hide the Youtube Logo
                        loop: 1, // Run the video in a loop
                        fs: 0, // Hide the full screen button
                        rel: 0,
                        enablejsapi: 1,
                    },
                    events: {
                        onReady: function (e) {
                            e.target.mute();
                            e.target.playVideo();
                        },
                        onStateChange: function onPlayerStateChange(e) {
                            if (e.data === YT.PlayerState.PLAYING) {
                                // Video is playing, check the playback time
                                let duration = player.getDuration();
                                let currentTime = player.getCurrentTime();

                                // Pause the video 2 seconds before the end
                                if (duration - currentTime <= 2) {
                                    player.pauseVideo();
                                }
                            }
                        }

                    }
                });
                youtubePlayers.push({
                    id: videoId,
                    player: player
                })
            })
            youtubeVideosBgLoop.forEach((item) => {
                const videoId = item.getAttribute('data-video-id');
                const player = new YT.Player(item, {
                    videoId: videoId,
                    playerVars: {
                        autoplay: 1, // Auto-play the video on load
                        autohide: 1, // Hide video controls when playing
                        disablekb: 1,
                        controls: 0, // Hide pause/play buttons in player
                        showinfo: 0, // Hide the video title
                        modestbranding: 1, // Hide the Youtube Logo
                        loop: 1, // Run the video in a loop
                        fs: 0, // Hide the full screen button
                        rel: 0,
                        enablejsapi: 1,
                    },
                    events: {
                        onReady: function (e) {
                            e.target.mute();
                            e.target.playVideo();
                        },
                    }
                });
                youtubePlayers.push({
                    id: videoId,
                    player: player
                })
            })
        })
    });
}